$primary:       #323b30;
$secondary:     #b2bbb2;
$success:       $green;
$info:          $cyan;
$warning:       $yellow;
$danger:        $red;
$light:         $gray-100;
$dark:          $gray-800;



// page

body{
	height: 100%;
	background-color: #888f88;
	color: $dark;
	a{
		color:$primary;
		&:hover{
			color:$light;
			text-decoration:none;
		}
	}
}

header{
  background-color: transparent;
  background-image: url(../../images/winter-hill.svg);
  background-size: 100%;
  background-repeat: no-repeat;
  height: auto;
  position: relative;
  margin-bottom: 0;

  @media (min-width: 576px) { 
    margin-bottom: 20px;
  } 

  @media (min-width: 768px) {
    margin-bottom: 20px;
  }

  @media (min-width: 992px) {
    margin-bottom: 20px;
  }

  .logo{

    padding-left:20px;
    padding-right:20px;
    padding-top:30px;
    padding-bottom:30px;


    @media (min-width: 576px) { 
    	max-width:50%;
    } 

    @media (min-width: 768px) {
    	max-width:40%;
    	padding-top:50px;
    	padding-bottom:50px;
    }

    @media (min-width: 992px) {
    	max-width:40%;
    	padding-top:70px;
    	padding-bottom:70px;
    }

    @media (min-width: 1200px) {
    	padding-top:110px;
    	padding-bottom:110px;
    	max-width:40%;
    }

  }
}

h1.logo{
  font-family: 'munki-boy';
  font-size: 2rem;
  font-weight: 500;
  margin-top: 30px;
  margin-bottom: 30px;
  &.large{
  	font-size: 4em;
  }
}

h1.title{
  font-size: 2rem;
  font-weight: 500;
  margin-top: 30px;
  margin-bottom: 29px;
  &.large{
  	font-size: 4em;
  }
}


// guttering

.container-fluid{
	padding-right:0;
	padding-left:0;
}

.row{
	margin-right:0;
	margin-left:0;
	&.bg1{
		padding-top:120px;
		padding-bottom:120px;
		background-color:$secondary;
		color:#fff;
	}
	&.primary{
		background-color: $dark;
		background: 
		linear-gradient(
			to bottom,
			$dark,
			#323232 40%,
			#323232 60%,
			$dark 100%
		);
		color: #ffffff;
		padding-top: 80px;
		padding-bottom: 80px;
		margin-bottom: 0;
		border-top: 2px solid #000000;
		border-bottom: 2px solid #000000;
	    @media (min-width: 576px) { 
			background-size: fit;
	    }

	    @media (min-width: 768px) {
			background-size: 100%;
	    }

	    @media (min-width: 992px) {
			background-size: 100%;
	    }
		h1{
			&.center{
				text-align: center;
			}
		}
		&.no-bgr{
			background: none;
			border-top: none;
			border-bottom: none;
			box-shadow: none;
			-moz-box-shadow: none;
			-webkit-box-shadow: none;
			color: $dark;
		}
	}
	&.secondary{
		background-color: transparent;
		padding-top: 20px;
		padding-bottom: 20px;
		margin-top: -80px;
		margin-bottom: 80px;
		.nav{
			button{
				&.navbar-toggler{
					color: $dark;
				}
			}
			a.nav-link{
				&:hover{
					color: $dark;
				}
			}
		}
	}
	&.pad-down{
		margin-top: 60px;
	}
	&.pad-in{
		margin-left: 15px;
		margin-right: 15px;
	}
	&.pad-out{
		margin-left: -15px;
		margin-right: -15px;
	}
}

*[class*='col-'] {
    padding-right:0;
    padding-left:0;
}

.add-gutter > [class*='col-'] {
    padding-right: 15px;
    padding-left: 15px;
    &.pad-in{
    	padding-right: 30px;
    	padding-left: 30px;
    }
}

//navbar

.sidebar-nav{

	.navbar{
		background-color: rgba(255, 255, 255, 0.5);
		border-radius: 0.25rem;
		border: 1px solid #ddd;
		margin-bottom: 30px;
		min-height: 60px;
	}

	.navbar-brand{
	  img{
	    height:50px;
	  }
	}

	.nav-link{
		float:left;
	}

	.navbar-light .navbar-toggler-icon{
	  width:auto;
	  padding-top:5px;
	}

	.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
	    color: rgba(88, 88, 88, 1);
	}

	.navbar-light .navbar-nav .nav-link {
	  color: rgba(88, 88, 88, 1);
	}

	.navbar-light .navbar-nav .open > .nav-link, .navbar-light .navbar-nav .active > .nav-link, .navbar-light .navbar-nav .nav-link.open, .navbar-light .navbar-nav .nav-link.active {
	  color: rgba(88, 88, 88, 1);
	}

	.navbar-light .navbar-toggler {
	  border-color: rgba(88, 88, 88, 1);
	}

	.navbar-light .navbar-brand, .navbar-light .navbar-toggler {
	  color: rgba(88, 88, 88, 1);
	}

	.navbar-light .navbar-brand:focus, .navbar-light .navbar-brand:hover, .navbar-light .navbar-toggler:focus, .navbar-light .navbar-toggler:hover {
	  color: rgba(88, 88, 88, 1);
	}

	.navbar-light .navbar-toggler-icon {
		background-image: none;
	}

	.navbar-toggleable-md .navbar-nav {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

}

.bnav{
	padding-top:60px;
}

.main-heading{
	margin-bottom:120px;
}

// image-rendering

.image-size{
	width: 100%;
}

.img-fluid {
	/* max-width: 100%; */
	/* height: auto; */
	width:100%;
}



// sections

.intro, .main-header{
	background-color: #ffffff;

}

.intro{
	padding-top: 70px;
	height: 300px;
	display: flex;


    @media (min-width: 34em) {
		height: 400px;
    } 

    @media (min-width: 576px) { 
		height: 500px;
    }

    @media (min-width: 768px) {
		height: 500px;
    }

    @media (min-width: 992px) {
		height: 600px;
    }


	.jumbotron {
		align-self: center;
		background: transparent;
		color: $dark;
		text-align: center;
		width: 100%;
			h1,h2,p{
				a{
					color:$dark;
					&:link{
						color:$dark;
					}
					&:hover,&:focus,&:link{
						text-decoration: none;
					}
				}
			}
			h1.logo{
			  font-family: 'munki-boy';
			  font-size: 2rem;
			  font-weight: 500;
			  margin-top: 10px;
			  &.large{
			  	font-size: 4em;
			  }
			}
			h2.logo-tag{
			  font-size: 1rem;
			  font-weight: 500;
			  margin-top: 0px;
			}
	        @media (min-width: 34em) {
				h1.logo{

				  font-family: 'munki-boy';
				  font-size: 3rem;
				  font-weight: 500;
				  margin-top: 10px;
				  &.large{
				  	font-size: 5em;
				  }
				}
				h2.logo-tag{
				  font-size: 1.5rem;
				  font-weight: 500;
				  margin-top: 0px;
				}
	        } 

	        @media (min-width: 576px) { 
				h1.logo{

				  font-family: 'munki-boy';
				  font-size: 4rem;
				  font-weight: 500;
				  margin-top: 10px;
				  &.large{
				  	font-size: 6em;
				  }
				}
				h2.tag{
				  font-size: 2rem;
				  font-weight: 500;
				  margin-top: 0px;
				}
	        }

	        @media (min-width: 768px) {
				h1.logo{

				  font-family: 'munki-boy';
				  font-size: 6rem;
				  font-weight: 500;
				  margin-top: 10px;
				  &.large{
				  	font-size: 8em;
				  }
				}
				h2.tag{
				  font-size: 3rem;
				  font-weight: 500;
				  margin-top: 0px;
				}
	        }

	        @media (min-width: 992px) {
				h1.logo{

				  font-family: 'munki-boy';
				  font-size: 6rem;
				  font-weight: 500;
				  margin-top: 10px;
				  &.large{
				  	font-size: 10em;
				  }
				}
				h2.tag{
				  font-size: 3rem;
				  font-weight: 500;
				  margin-top: 0px;
				}
	        }
	}
	.jumbotron-btn {
		background: #fff;
		color: #111111;
	}
	.jumbotron-btn:hover {
		background: rgb(24,53,50);
		color: #fff;
		border: 1px solid #fff;
	}
}

.main-header{
	padding-top: 70px;
	height: 300px;
	display: flex;

	.jumbotron {
		align-self: center;
		background: transparent;
		color: $dark;
		text-align: center;
		width: 100%;
			h1,h2,p{
				a{
					color:$dark;
					&:link{
						color:$dark;
					}
					&:hover,&:focus,&:link{
						text-decoration: none;
					}
				}
			}
			h1.logo{
			  font-family: 'munki-boy';
			  font-size: 2rem;
			  font-weight: 500;
			  margin-top: 10px;
			  &.large{
			  	font-size: 4em;
			  }
			}
			h2.logo-tag{
			  font-size: 1rem;
			  font-weight: 500;
			  margin-top: 0px;
			}
	        @media (min-width: 34em) {
				h1.logo{

				  font-family: 'munki-boy';
				  font-size: 3rem;
				  font-weight: 500;
				  margin-top: 10px;
				  &.large{
				  	font-size: 5em;
				  }
				}
				h2.logo-tag{
				  font-size: 1.5rem;
				  font-weight: 500;
				  margin-top: 0px;
				}
	        } 

	        @media (min-width: 576px) { 
				h1.logo{

				  font-family: 'munki-boy';
				  font-size: 4rem;
				  font-weight: 500;
				  margin-top: 10px;
				  &.large{
				  	font-size: 6em;
				  }
				}
				h2.tag{
				  font-size: 2rem;
				  font-weight: 500;
				  margin-top: 0px;
				}
	        }

	        @media (min-width: 768px) {
				h1.logo{

				  font-family: 'munki-boy';
				  font-size: 6rem;
				  font-weight: 500;
				  margin-top: 10px;
				  &.large{
				  	font-size: 8em;
				  }
				}
				h2.tag{
				  font-size: 3rem;
				  font-weight: 500;
				  margin-top: 0px;
				}
	        }

	        @media (min-width: 992px) {
				h1.logo{

				  font-family: 'munki-boy';
				  font-size: 6rem;
				  font-weight: 500;
				  margin-top: 10px;
				  &.large{
				  	font-size: 10em;
				  }
				}
				h2.tag{
				  font-size: 3rem;
				  font-weight: 500;
				  margin-top: 0px;
				}
	        }
	}
	.jumbotron-btn {
		background: #fff;
		color: #111111;
	}
	.jumbotron-btn:hover {
		background: rgb(24,53,50);
		color: #fff;
		border: 1px solid #fff;
	}
}


.card-columns {

  @include media-breakpoint-only(sm) {
    column-count: 1;
  }
  @include media-breakpoint-only(md) {
    column-count: 3;
  }
  @include media-breakpoint-only(lg) {
    column-count: 3;
  }
  @include media-breakpoint-only(xl) {
    column-count: 3;
  }

  &.side-col{
    @include media-breakpoint-only(sm) {
      column-count: 2;
    }
    @include media-breakpoint-only(md) {
      column-count: 2;
    }
    @include media-breakpoint-only(lg) {
      column-count: 3;
    }
    @include media-breakpoint-only(xl) {
      column-count: 1;
    }
  }  
}

.card{
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  border-color: #ddd;
  background-color: $secondary;
  margin-bottom: 1.3rem;
  &.ad-rectangle{
  	background-color:transparent;
  	border:none;
  }
}

.card-body{
  h1{
    font-size: 1.5em;
  }
  &.split{
    padding-left: 0;
    padding-right: 0;
  }
}



iframe{

  &.map, &.youtube{
    border:none;
    height: 500px;
    width: 100%;
    margin-bottom: 30px;

  }

}

a.youtube-thumb,
a.youtube-link {
  text-decoration: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
}

.video-container { 
  position: relative; 
  padding-bottom: 56.25%; 
  padding-top: 30px; 
  height: 0; 
  overflow: hidden; 

  &.home{
    margin-top: 30px;
    margin-bottom: 30px;
  }

} 
.video-container iframe, .video-container object, .video-container embed { 
  position: absolute; 
  top: 0; 
  left: 0; 
  width: 100%; 
  height: 100%;
}

  h4.m-link{

    font-family: 'munki-boy';
    font-size: 1.5em;
    font-weight: 500;
    text-align: center;
  }

.mini-logo{
	max-width:300px;
	margin-left:auto;
	margin-right:auto;
}